import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Link } from "react-router-dom";

const HomeScreen = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const [role, setRole] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log(user);
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims.admin) {
              // Show admin UI.
              setRole("Admin");
              setMessage("Be an admin.");
            } else {
              setRole(null);
              setMessage("Your request for admin has been sent.");
            }
            if (!!idTokenResult.claims.owner) {
              // Show regular user UI.
              setRole("Owner");
              setMessage("Yo dawg");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // User is signed out
        // ...
      }
    });
  }, []);

  if (!user)
    return (
      <div className="flex flex-1 items-center justify-center flex-col">
        <Link
          className={"text-xl text-white text-center font-black opacity-50"}
          to={"/login"}
        >
          Login
        </Link>
      </div>
    );

  return (
    <div className="flex flex-1 items-center justify-center flex-col">
      <div className={"flex justify-center items-center mt-8"}>
        <img
          className="rounded-full mr-4 w-16 h-16"
          src={user?.photoURL ? user.photoURL : undefined}
          alt="avatar"
        />
        <div>
          <h2 className={"text-white text-lg font-black opacity-100"}>
            {user?.displayName}
          </h2>
          <h2 className={"text-white text-sm font-black opacity-50"}>{role}</h2>
        </div>
      </div>

      <div className="flex-1 flex items-center justify-center mt-8">
        <div
          className="flex bg-white px-6 py-8"
          style={{ width: "30vh", height: "40vh", borderRadius: 30 }}
        >
          <p className="flex-1 text-black font-black text-base text-left">
            {message}
          </p>
        </div>
      </div>
      <div className="flex-1" />
    </div>
  );
};

export default HomeScreen;
