import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate, useLocation } from "react-router-dom";

const RestrictedAccess = ({
  children,
  whitelist = [],
}: {
  children: any;
  whitelist?: string[];
}) => {
  const auth = getAuth();
  const user = auth.currentUser;
  const location = useLocation();
  const [authState, setAuthState] = useState("loading");
  const [access, setAccess] = useState(false);

  useEffect(() => {
    setAccess(false);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;

          const idTokenResult = await user.getIdTokenResult();
          const { claims } = idTokenResult;

          const accessCheck = Object.entries(claims).some(
            ([key, value]) => whitelist.includes(key) && value
          );

          if (!!claims) {
            // @ts-ignore
            setAccess(accessCheck || claims?.owner);
          }
        } else {
          // User is signed out
          // ...
        }
        setAuthState("complete");
      } catch (e) {
        console.error(e);
      }
    });

    return () => unsubscribe();
  }, [auth, whitelist]);

  if (authState === "loading")
    return (
      <div>
        <h1 className="text-white text-lg font-black opacity-50">Loading...</h1>

        <div>
          <input type="button" />
        </div>
      </div>
    );

  return user ? (
    access ? (
      children
    ) : (
      <div className="flex-1 flex items-center justify-center mt-8">
        <div
          className="flex bg-white px-6 py-8"
          style={{ width: "30vh", height: "40vh", borderRadius: 30 }}
        >
          <p className="flex-1 text-black font-black text-base text-left">
            You are not authorized.
          </p>
        </div>
      </div>
    )
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
};

export default RestrictedAccess;
