import React, { useEffect, useState } from "react";
import "./moderation.css";
import {
  query,
  collection,
  limit,
  getDocs,
  FieldValue,
  serverTimestamp,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../config/fbconfig";
import { PromptType, RiskLevelType } from "../../types/types";
import { getAuth } from "firebase/auth";

const ModerationScreen = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const [prompts, setPrompts] = useState<PromptType[]>([]);
  const prompt = prompts[0];
  const [promptText, setPromptText] = useState(prompt ? prompt.text : "");
  const [riskLevel, setRiskLevel] = useState<RiskLevelType>(
    prompt ? prompt.riskLevel : null
  );
  // TODO: handle when all prompts have been moderated

  useEffect(() => {
    if (prompt) setPromptText(prompt.text);
  }, [prompt]);

  const handleRiskLevel = (level: RiskLevelType) => {
    setRiskLevel(riskLevel !== level ? level : null);
  };

  const getPrompts = async () => {
    const q = query(collection(db, "drafts"), limit(10));

    const querySnapshot = await getDocs(q);
    const prompts: PromptType[] = [];
    querySnapshot.forEach((doc) => {
      const {
        approved,
        createdAt,
        creator,
        reviewedAt,
        reviewer,
        riskLevel,
        text,
      } = doc.data();
      prompts.push({
        approved,
        createdAt,
        creator,
        reviewedAt,
        reviewer,
        riskLevel,
        text,
        id: doc.id,
      });
    });

    console.log(prompts);
    setPrompts(prompts);
  };

  const handleSubmit = async (approve: boolean) => {
    if (user && riskLevel !== null) {
      const { displayName, email, uid, photoURL } = user;
      const { createdAt, creator, id, text } = prompt;

      const promptPayload = {
        approved: approve,
        createdAt,
        creator,
        reviewedAt: serverTimestamp(),
        reviewer: { displayName, email, uid, photoURL },
        riskLevel,
        text: promptText,
      };

      console.log(
        `Prompt ${prompt.id} is ${approve ? "Approved" : "Denied"}`,
        promptPayload
      );

      try {
        const docRef = await addDoc(
          collection(db, approve ? "prompts" : "denied"),
          promptPayload
        );
        console.log("Prompt written with ID: ", docRef.id);
        await removePrompt(prompt.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  const removePrompt = async (id: string) => {
    try {
      await deleteDoc(doc(db, "drafts", id));
      console.log("Deleted", id);
      const remainingPrompts = prompts.filter((prompt) => prompt.id !== id);
      console.log(remainingPrompts);
      await setPrompts(remainingPrompts);
      if (remainingPrompts.length === 0) await getPrompts();
    } catch (e) {
      console.error("Error deleting document", e);
    }
  };

  useEffect(() => {
    (async () => await getPrompts())();
  }, []);

  return (
    <div className="flex flex-1 flex-col h-full">
      <div className="flex-4 flex flex-col items-center justify-center">
        <div
          className="flex bg-white px-6 py-8"
          style={{ width: "34vh", height: "44vh", borderRadius: 30 }}
        >
          <textarea
            className="flex-1 text-black font-black text-base text-left"
            value={promptText}
            onChange={(e) => setPromptText(e.target.value)}
          />
        </div>
        <div className={'flex m-8'}>
          <img src={prompt?.creator?.photoURL ? prompt.creator.photoURL : ""} alt="" className={'rounded-full h-10 w-10 mr-4'}/>
          <h2 className={'text-white font-black text-base text-left'}>{prompt?.creator?.displayName}</h2>
        </div>
        <div className={"flex w-full px-2"}>
          <div
            className={`flex-1 chip ${riskLevel === "safe" && "green"}`}
            onClick={() => handleRiskLevel("safe")}
          >
            <h2 className={`chip-text ${riskLevel === "safe" && "green"}`}>
              Safe
            </h2>
          </div>
          <div
            className={`flex-1 chip ${riskLevel === "risky" && "yellow"}`}
            onClick={() => handleRiskLevel("risky")}
          >
            <h2 className={`chip-text ${riskLevel === "risky" && "yellow"}`}>
              Risky
            </h2>
          </div>
          <div
            className={`flex-1 chip ${riskLevel === "danger" && "red"}`}
            onClick={() => handleRiskLevel("danger")}
          >
            <h2 className={`chip-text ${riskLevel === "danger" && "red"}`}>
              Danger
            </h2>
          </div>
        </div>
      </div>

      <div className={"flex flex-1"}>
        <div
          className={"flex-1 flex justify-center"}
          onClick={() => handleSubmit(false)}
        >
          <h1 className="flex-1 text-red text-xl font-black text-center items-center flex justify-center">
            Deny
          </h1>
        </div>
        <div
          className={"flex-1 flex justify-center"}
          onClick={() => handleSubmit(true)}
        >
          <h1 className="flex-1 text-green text-xl font-black text-center items-center flex justify-center">
            Accept
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ModerationScreen;
