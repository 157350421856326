import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { CSSTransition } from "react-transition-group";

const NavMenu = ({ open, onClick }: { open: boolean; onClick: () => void }) => {
  const routes = [
    { label: "Home", slug: "" },
    { label: "Dashboard", slug: "dashboard", whitelist: ["owner"] },
    { label: "Prompt Finder", slug: "prompts", whitelist: ["admin"] },
    { label: "Moderation", slug: "moderation", whitelist: ["owner"] },
    { label: "Users", slug: "users", whitelist: ["owner"] },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const nodeRef = useRef(null);
  const [userClaims, setUserClaims] = useState<string[]>([]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          let claims = [];

          if (!!idTokenResult.claims.admin) claims.push("admin");
          if (!!idTokenResult.claims.owner) claims.push("owner");

          setUserClaims(claims);
        });
      } else {
        setUserClaims([]);
      }
    });
  }, []);

  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
        onClick();
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={open}
      timeout={500}
      classNames="nav"
      unmountOnExit
    >
      <div ref={nodeRef} className="nav bg-black">
        {!user && (
          <Link
            className="flex-1 items-center justify-center flex"
            to={`/login`}
            onClick={onClick}
          >
            <h1
              className={`text-white text-xl font-black text-center opacity-50 ${
                location.pathname === `/login` ? "active" : undefined
              }`}
            >
              Login
            </h1>
          </Link>
        )}
        {user &&
          routes.map((route) => {
            if (
              !route.whitelist ||
              route.whitelist.some((item) => userClaims.includes(item))
            )
              return (
                <Link
                  key={route.slug}
                  className="flex-1 items-center justify-center flex"
                  to={`/${route.slug}`}
                  onClick={onClick}
                >
                  <h1
                    className={`text-white text-xl font-black text-center opacity-50 ${
                      location.pathname === `/${route.slug}`
                        ? "active"
                        : undefined
                    }`}
                  >
                    {route.label}
                  </h1>
                </Link>
              );
          })}
        {user && (
          <div
            className="flex-1 items-center justify-center flex flex-col"
            onClick={handleLogOut}
          >
            <h1
              className={`text-white text-xl font-black text-center opacity-50`}
            >
              Logout
            </h1>
          </div>
        )}
      </div>
    </CSSTransition>
  );
};

export default NavMenu;
