import React from "react";
import { AVATAR } from "../../styles/images";

const UsersScreen = () => {
  const users = [
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "last" },
  ];

  return (
    <div className="flex flex-1 mx-4 justify-center flex-col">
      <ul className={"overflow-hidden overflow-y-scroll h-[42rem]"}>
        {users &&
          users.map((user, index) => (
            <li key={index} className={"flex items-center my-8"}>
              <img
                className="rounded-full mr-4 w-12 h-12"
                src={AVATAR}
                alt="avatar"
              />
              <div className={"flex-1"}>
                <h2 className={"text-white text-body font-black opacity-100"}>
                  Christian Overton
                </h2>
                <h2 className={"text-white text-sm font-black opacity-50"}>
                  {user.name}
                </h2>
              </div>
              <div>
                <div
                  className={
                    "bg-white/10 rounded-full w-24 py-0.5 mx-2 transition-all ease-in-out"
                  }
                >
                  <h2
                    className={
                      "text-white text-body font-black opacity-50 text-center"
                    }
                  >
                    Owner
                  </h2>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default UsersScreen;
