import React, { useState } from "react";
import "./App.css";
import Login from "./scenes/login";
import { useLocation, Routes, Route } from "react-router-dom";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { AuthContextProvider, useAuthState } from "./utils/auth";
import RestrictedAccess from "./components/atoms/RestrictedAccess";
import HomeScreen from "./scenes/home";
import NavMenu from "./components/molecules/NavMenu";
import PromptsScreen from "./scenes/promps";
import ModerationScreen from "./scenes/moderation";
import SettingsScreen from "./scenes/settings";
import UsersScreen from "./scenes/users";
import DashboardScreen from "./scenes/dashboard";

function App() {
  const auth = getAuth();
  const user = auth.currentUser;
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const headerLabel = (path: string) => {
    switch (path) {
      case "":
        return "Home";
      case "dashboard":
        return "Dashboard";
      case "prompts":
        return "Prompt Finder";
      case "moderation":
        return "Moderation";
      case "settings":
        return "Settings";
      case "users":
        return "Users";
      default:
        return "Header";
    }
  };

  return (
    <div className="App">
      <AuthContextProvider>
        {location?.pathname !== "/login" && (
          <h1
            className="flex-1 text-white text-xl font-black text-center items-center flex justify-center"
            onClick={() => setOpen(!open)}
          >
            {headerLabel(location?.pathname.slice(1))}
          </h1>
        )}
        <NavMenu open={open} onClick={() => setOpen(false)} />

        <div className={"flex-5"}>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/login" element={<Login />} />

            <Route
                path="/dashboard"
                element={
                  <RestrictedAccess whitelist={["owner"]}>
                    <DashboardScreen />
                  </RestrictedAccess>
                }
            />

            <Route
              path="/prompts"
              element={
                <RestrictedAccess whitelist={["admin"]}>
                  <PromptsScreen />
                </RestrictedAccess>
              }
            />
            <Route
              path="/moderation"
              element={
                <RestrictedAccess whitelist={["owner"]}>
                  <ModerationScreen />
                </RestrictedAccess>
              }
            />
            <Route
              path="/users"
              element={
                <RestrictedAccess whitelist={["owner"]}>
                  <UsersScreen />
                </RestrictedAccess>
              }
            />
          </Routes>
        </div>
      </AuthContextProvider>
    </div>
  );
}

export default App;
