import React, { useEffect, useState } from 'react'
import {
  collection,
  getDocs,
  limit,
  query,
  onSnapshot,
  where,
  deleteDoc,
  doc,
  serverTimestamp,
  addDoc,
  updateDoc,
} from 'firebase/firestore'
import { db } from '../../config/fbconfig'
import { PromptType, RiskLevelType } from '../../types/types'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { useSortableData } from '../../utils/useSortableData'
import Modal from '../../components/atoms/Modal'
import moment from 'moment'
import { getAuth } from 'firebase/auth'
import firebase from 'firebase/compat'

type SortablePromptType = PromptType & {
  createdAtSeconds: number
  reviewedAtSeconds: number
  creatorDisplayName: string
}

const data: SortablePromptType[] = []

type ReasonType = 'duplicate' | string

type Diff<T extends string, U extends string> = ({ [P in T]: P } & {
  [P in U]: never
} & { [x: string]: never })[T]

// @ts-ignore
type Omit<T, K extends keyof T> = Pick<T, Diff<keyof T, K>>

const DashboardScreen = () => {
  const auth = getAuth()
  const user = auth.currentUser
  // @ts-ignore
  const [prompts, setPrompts] = useState<SortablePromptType[]>(data)

  const [search, setSearch] = useState('')

  const [filter, setFilter] = useState<null | 'safe' | 'risky' | 'danger'>(null)

  const { items, requestSort, sortConfig } = useSortableData(prompts)

  const [open, setOpen] = useState(false)

  const [selected, setSelected] = useState<PromptType | null>(null)

  const [reason, setReason] = useState<ReasonType | null>(null)

  const [modalTab, setModalTab] = useState<'edit' | 'delete'>('edit')

  useEffect(() => {
    console.log('async...')
    const q = query(collection(db, 'prompts'))
    const unsubscribe = onSnapshot(q, querySnapshot => {
      console.log('Snapshot')
      const prompts: SortablePromptType[] = []
      querySnapshot.forEach(doc => {
        const {
          apple,
          approved,
          createdAt,
          creator,
          reviewedAt,
          reviewer,
          riskLevel,
          text,
        } = doc.data()
        prompts.push({
          apple,
          approved,
          createdAt,
          creator,
          reviewedAt,
          reviewer,
          riskLevel,
          text,
          id: doc.id,
          createdAtSeconds: createdAt?.seconds ? createdAt.seconds : 0,
          creatorDisplayName: creator?.displayName ? creator.displayName : '',
          reviewedAtSeconds: reviewedAt?.seconds ? reviewedAt.seconds : 0,
        })
      })

      console.log({ prompts })
      setPrompts(prompts)
      console.log('prompts have been set')
    })

    return () => unsubscribe()
  }, [])

  /* useEffect(() => {
    const prompts: SortablePromptType[] = []
    localPrompts.forEach(doc => {
      const {
        approved,
        createdAt,
        creator,
        reviewedAt,
        reviewer,
        riskLevel,
        text,
      } = doc

      prompts.push({
        approved,
        // @ts-ignore
        createdAt,
        creator,
        reviewedAt,
        reviewer,
        // @ts-ignore
        riskLevel,
        text,
        id: doc.id,
        createdAtSeconds: createdAt?.seconds ? createdAt.seconds : 0,
        creatorDisplayName: creator?.displayName ? creator.displayName : '',
        reviewedAtSeconds: reviewedAt?.seconds ? reviewedAt.seconds : 0,
      })
    })

    console.log({ prompts })
    setPrompts(prompts)
  }, [])*/

  const getPrompts = async () => {
    const q = query(collection(db, 'prompts'))
    const querySnapshot = await getDocs(q)
    const prompts: PromptType[] = []
    querySnapshot.forEach(doc => {
      const {
        approved,
        createdAt,
        creator,
        reviewedAt,
        reviewer,
        riskLevel,
        text,
      } = doc.data()
      prompts.push({
        approved,
        createdAt,
        creator,
        reviewedAt,
        reviewer,
        riskLevel,
        text,
        id: doc.id,
      })
    })

    console.log(prompts)
    // setPrompts(prompts)
  }

  const downloadPrompts = () => {
    console.log('downloading')
    let csvContent = 'data:text/csv;charset=utf-8,'

    prompts.forEach(function (prompt) {
      let row = `${prompt.id};${
        prompt.creator?.displayName
      };${prompt.text.replace(/,/g, '-')};${prompt.riskLevel};`
      csvContent += row + '\r\n'
    })

    console.log({ csvContent })

    const encodedUri = encodeURI(csvContent)
    console.log({ encodedUri })
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'prompts.csv')
    document.body.appendChild(link)

    link.click()
  }

  const handleModal = async (state: boolean = false) => {
    await setOpen(state)
    if (!state) setModalTab('edit')
  }

  const handleSearch = (text: string) => {
    setSearch(text)
  }

  const handleEditText = (text: any) => {
    if (selected) setSelected({ ...selected, text: text })
  }

  const handleRiskLevel = (riskLevel: RiskLevelType) => {
    if (selected) setSelected({ ...selected, riskLevel })
  }

  const handleUpdatePrompt = async () => {
    if (user && selected) {
      const { displayName, email, uid, photoURL } = user
      const promptPayload: Omit<PromptType, 'id'> = {
        text: selected.text,
        creator: selected.creator,
        reviewer: { displayName, email, uid, photoURL },
        riskLevel: selected.riskLevel,
        approved: selected.approved,
        createdAt: selected.createdAt,
        reviewedAt: serverTimestamp(),
      }

      try {
        const docRef = doc(db, 'prompts', selected.id)
        await updateDoc(docRef, promptPayload)
        console.log('Prompt updated, doc written with ID: ', docRef.id)
        await handleModal(false)
      } catch (e) {
        console.error('Error adding document: ', e)
      }
    }
  }

  const handleArchivePrompt = async () => {
    if (user && reason && selected) {
      const { displayName, email, uid, photoURL } = user

      const promptPayload: Omit<PromptType, 'id'> = {
        text: selected.text,
        creator: selected.creator,
        reviewer: { displayName, email, uid, photoURL },
        riskLevel: selected.riskLevel,
        approved: selected.approved,
        createdAt: selected.createdAt,
        reviewedAt: serverTimestamp(),
      }

      try {
        const docRef = await addDoc(collection(db, 'denied'), promptPayload)
        console.log('Prompt moved to denied, doc written with ID: ', docRef.id)
        await removePrompt(selected.id)
      } catch (e) {
        console.error('Error adding document: ', e)
      }
    }
  }

  const removePrompt = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'prompts', id))
      console.log('Deleted', id)
    } catch (e) {
      console.error('Error deleting document', e)
    }
  }

  const handleFilter = (riskLevel: null | 'safe' | 'risky' | 'danger') => {
    setFilter(riskLevel)
  }

  const handleApple = async (prompt: PromptType) => {
    if (user) {
      try {
        console.log(prompt.apple, !prompt.apple)
        const docRef = doc(db, 'prompts', prompt.id)
        await updateDoc(docRef, { apple: !prompt.apple })
        console.log('Prompt updated, doc written with ID: ', docRef.id)
      } catch (e) {
        console.error('Error updating document: ', e)
      }
    }
  }

  return (
    <div>
      <Modal open={open} setOpen={(state: boolean) => handleModal(state)}>
        {selected && (
          <div>
            {selected.creator?.photoURL && (
              <img
                src={selected.creator.photoURL}
                alt=""
                className={'h-10 w-10 rounded-full'}
              />
            )}
            <h2 className={'text-white opacity-50'}>{selected.id}</h2>
            <textarea
              className={'w-full my-8 text-white bg-lightGray p-2'}
              value={selected.text}
              onChange={e => handleEditText(e.target.value)}
            />

            <div className={'flex my-4 w-full px-2 pb-4 bg-black rounded'}>
              <div
                className={`flex-1 chip ${
                  selected.riskLevel === 'safe' && 'green'
                }`}
                onClick={() => handleRiskLevel('safe')}>
                <h2
                  className={`chip-text ${
                    selected.riskLevel === 'safe' && 'green'
                  }`}>
                  Safe
                </h2>
              </div>
              <div
                className={`flex-1 chip ${
                  selected.riskLevel === 'risky' && 'yellow'
                }`}
                onClick={() => handleRiskLevel('risky')}>
                <h2
                  className={`chip-text ${
                    selected.riskLevel === 'risky' && 'yellow'
                  }`}>
                  Risky
                </h2>
              </div>
              <div
                className={`flex-1 chip ${
                  selected.riskLevel === 'danger' && 'red'
                }`}
                onClick={() => handleRiskLevel('danger')}>
                <h2
                  className={`chip-text ${
                    selected.riskLevel === 'danger' && 'red'
                  }`}>
                  Danger
                </h2>
              </div>
            </div>

            {modalTab === 'edit' && (
              <div>
                <div className={'flex'}>
                  <button
                    onClick={() => handleModal(false)}
                    type="button"
                    className="flex-1 bg-gray text-white rounded px-6 py-2">
                    Cancel
                  </button>
                  <div className={'m-2'} />
                  <button
                    onClick={() => handleUpdatePrompt()}
                    type="button"
                    className="flex-1 bg-green text-white rounded px-6 py-2">
                    Update
                  </button>
                </div>
                <div className={'flex'}>
                  <button
                    onClick={() => setModalTab('delete')}
                    type="button"
                    className="mt-4 flex-1 text-red">
                    Delete Prompt
                  </button>
                </div>
              </div>
            )}

            {modalTab === 'delete' && (
              <div>
                <input
                  type="text"
                  value={reason ? reason : ''}
                  onChange={e => setReason(e.target.value)}
                />
                <div className={'mb-4'}>
                  <span
                    onClick={() => setReason('Duplicate')}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-lightGray text-gray">
                    Duplicate
                  </span>
                  <span
                    onClick={() => setReason('Lame')}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-lightGray text-gray">
                    Lame
                  </span>
                </div>
                <div className={'flex'}>
                  <button
                    onClick={() => setModalTab('edit')}
                    type="button"
                    className="flex-1 bg-gray text-white rounded px-6 py-2">
                    Cancel
                  </button>
                  <div className={'m-2'} />
                  <button
                    onClick={() => handleArchivePrompt()}
                    type="button"
                    className="flex-1 bg-red text-white rounded px-6 py-2">
                    Archive
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal>
      <input type="text" onChange={e => handleSearch(e.target.value)} />
      <div className={'flex my-4 w-full px-2 pb-4 bg-black rounded'}>
        <div
          className={`flex-1 chip ${filter === 'safe' && 'green'}`}
          onClick={() => handleFilter('safe')}>
          <h2 className={`chip-text ${filter === 'safe' && 'green'}`}>Safe</h2>
        </div>
        <div
          className={`flex-1 chip ${filter === 'risky' && 'yellow'}`}
          onClick={() => handleFilter('risky')}>
          <h2 className={`chip-text ${filter === 'risky' && 'yellow'}`}>
            Risky
          </h2>
        </div>
        <div
          className={`flex-1 chip ${filter === 'danger' && 'red'}`}
          onClick={() => handleFilter('danger')}>
          <h2 className={`chip-text ${filter === 'danger' && 'red'}`}>
            Danger
          </h2>
        </div>
        <div
          className={`flex-1 chip ${filter == null && 'blue'}`}
          onClick={() => handleFilter(null)}>
          <h2 className={`chip-text ${filter == null && 'blue'}`}>Clear</h2>
        </div>
      </div>

      <Example
        prompts={items}
        search={search}
        requestSort={requestSort}
        sortConfig={sortConfig}
        setOpen={handleModal}
        setSelected={setSelected}
        filter={filter}
        handleApple={handleApple}
      />
      {/*<h2 className={'text-white font-black text-base text-left'}>Dashboard</h2>*/}

      {/*            <div
                className={"flex-1 flex justify-center"}
                onClick={() => downloadPrompts()}
            >
                <h1 className="flex-1 text-green text-xl font-black text-center items-center flex justify-center">
                    Download Prompts
                </h1>
            </div>*/}

      {/* <div className={'relative'}>
                <table className={'bg-white'}>
                    <thead>
                    <tr>
                        <th>id</th>
                        <th>creator</th>
                        <th>prompt</th>
                    </tr>
                    </thead>

                    <tbody>
                    {prompts.map((prompt, index) => (
                        <tr key={prompt.id}>
                            <td>{prompt.id}</td>
                            <td>{prompt.creator?.displayName}</td>
                            <td>{prompt.text}</td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </div>*/}
    </div>
  )
}

export default DashboardScreen

function Example({
  prompts,
  search,
  requestSort,
  sortConfig,
  setOpen,
  setSelected,
  filter,
  handleApple,
}: {
  prompts: PromptType[]
  search: string
  requestSort: any
  sortConfig: any
  setOpen: any
  setSelected: any
  filter: null | 'safe' | 'risky' | 'danger'
  handleApple: any
}) {
  const handleSelect = (prompt: PromptType) => {
    setSelected(prompt)
    setOpen(true)
  }

  return (
    <table className={'w-full'}>
      <thead className={'text-left'}>
        <tr>
          <th onClick={() => requestSort('approved')}>
            <h2 className="pl-4 group inline-flex text-white font-black text-base">
              Approved
            </h2>
          </th>
          <th onClick={() => requestSort('createdAtSeconds')}>
            <h2 className="pl-4 group inline-flex text-white font-black text-base">
              Created
            </h2>
          </th>
          <th onClick={() => requestSort('reviewedAtSeconds')}>
            <h2 className="pl-4 group inline-flex text-white font-black text-base">
              Reviewed
            </h2>
          </th>
          <th onClick={() => requestSort('creatorDisplayName')}>
            <h2 className="pl-4 group inline-flex text-white font-black text-base">
              Creator
            </h2>
          </th>
          <th onClick={() => requestSort('text')}>
            <h2 className="pl-4 group inline-flex text-white font-black text-base">
              Prompt
            </h2>
          </th>
          <th onClick={() => requestSort('id')}>
            <h2 className="pl-4 group inline-flex text-white font-black text-base">
              ID
            </h2>
          </th>
        </tr>
      </thead>
      <tbody className="text-white">
        {prompts &&
          prompts.map((prompt: PromptType, index) => {
            if (
              !prompt.text.toUpperCase().includes(search.toUpperCase()) &&
              search !== ''
            )
              return

            if (filter !== null && prompt.riskLevel?.toLowerCase() !== filter)
              return

            return (
              <tr
                key={prompt.id}
                className={`${index % 2 === 0 ? 'bg-white/10' : ''}`}
                onClick={() => handleSelect(prompt)}>
                <td
                  className="p-4 opacity-50 whitespace-nowrap text-sm text-gray-500"
                  onClick={e => {
                    e.stopPropagation()
                    handleApple(prompt)
                  }}>
                  {prompt.apple ? '✅' : ''}
                </td>
                {prompt?.createdAt?.seconds && (
                  <td className="p-4 opacity-50 whitespace-nowrap text-sm text-gray-500">
                    {moment(new Date(prompt.createdAt.seconds * 1000)).format(
                      'MM/DD/YY hh:mm:ss',
                    )}
                  </td>
                )}
                {prompt?.reviewedAt?.seconds && (
                  <td className="p-4 opacity-50 whitespace-nowrap text-sm text-gray-500">
                    {moment(new Date(prompt.reviewedAt.seconds * 1000)).format(
                      'MM/DD/YY hh:mm:ss',
                    )}
                  </td>
                )}
                <td className="pl-4 whitespace-nowrap text-sm font-medium text-gray-900 align">
                  {prompt.creator?.photoURL && (
                    <div>
                      <img
                        src={prompt.creator?.photoURL}
                        alt=""
                        className={'h-10 w-10 rounded-full'}
                      />
                      {prompt.creator.displayName}
                    </div>
                  )}
                </td>
                <td className="p-4 opacity-50 whitespace-nowrap text-sm text-gray-500">
                  {prompt.text}
                </td>
                <td className="p-4 opacity-50 whitespace-nowrap text-sm text-gray-500">
                  {prompt.id}
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}
