import React, { createContext, useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const AuthContext = createContext(null);

export const AuthContextProvider = (props: any) => {
  const auth = getAuth();
  const [user, setUser] = useState<any>(auth.currentUser);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError);
    return () => unsubscribe();
  }, []);
  return <AuthContext.Provider value={{ user, error }} {...props} />;
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  // @ts-ignore
  return { ...auth, isAuthenticated: auth.user != null };
};
