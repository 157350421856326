import React, { useEffect } from "react";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import { provider } from "../../config/fbconfig";
import { useNavigate, useLocation } from "react-router-dom";
import { Images } from "../../styles";

const Login = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleLogin = async () => {
    try {
      const auth = getAuth();
      const res = await signInWithRedirect(auth, provider);
      console.log(res);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    getRedirectResult(auth)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        // @ts-ignore
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // @ts-ignore
        const token = credential.accessToken;

        // The signed-in user info.
        // @ts-ignore
        const user = result.user;
        console.log("user", user);
        // @ts-ignore
        navigate(state?.path || "/");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }, []);

  const checkUser = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    console.log("current user", user);
  };

  return (
    <div className="flex flex-1 flex-col h-full">
      <h1 className="text-white text-xl font-black text-center mt-8">
        Stranger Danger
      </h1>
      <h2 className="text-blue text-xl font-black text-center">Admin</h2>
      <div className="flex-1" />
      <div
        className="flex items-center justify-center rounded-xl mx-8"
        style={{ backgroundColor: "rgba(255,255,255,0.1)" }}
        onClick={handleLogin}
      >
        <img
          className={"mr-2"}
          src={Images.GOOGLE_G}
          alt="G"
          width={50}
          height={50}
        />
        <h2 className="text-white text-lg font-black">Login with Google</h2>
      </div>
      <div className="" style={{ flex: 4 }} />
      {/*<h1 className="text-white text-lg font-black opacity-50" onClick={checkUser}>*/}
      {/*  check user*/}
      {/*</h1>*/}
    </div>
  );
};

export default Login;
