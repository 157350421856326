import React, { useState } from "react";
import Carousel, { CarouselItem } from "../../components/atoms/Carousel";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../config/fbconfig";

const PromptsScreen = () => {
  const [seeds, setSeeds] = useState({
    a: "Call the last person on a stranger's phone.",
    b: "Steal the closest person's hat.",
  });
  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState<"seeds" | "prompts" | "bankrupt">(
    "seeds"
  );
  const [prompts, setPrompts] = useState<
    { id: string; text: string; selected?: boolean; docId?: string }[]
  >([]);

  const auth = getAuth();
  const user = auth.currentUser;

  const handleChange = (seed: "a" | "b", text: string) => {
    console.log(seeds, seed, text);
    setSeeds({
      ...seeds,
      [seed]: text,
    });
  };

  const handleGenerate = async () => {
    // TODO: generate real prompts from api

    if (user) {
      try {
        setLoading(true);

        const idToken = await user.getIdToken(true);

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${idToken}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ idToken, seeds });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };

        fetch(
          "https://us-central1-ai-entertainment.cloudfunctions.net/api/generatePrompts",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            const { prompts, coins, error } = result;

            if (error !== undefined) throw error;
            console.log(`Remaining coins ${coins}`);

            prompts.forEach((prompt: any, index: number) => {
              prompts[index] = {
                text: prompt,
                id: index,
                selected: false,
              };
            });
            setPrompts(prompts);
            setLoading(false);
            setScreen("prompts");
          })
          .catch((error) => {
            console.log("error", error);
            if (error.code === 0) setScreen("bankrupt");
            setLoading(false);
          });
      } catch (e) {
        console.error(e);
        setLoading(false);
        setScreen("seeds");
      }
    } else {
      console.error("User must be signed in");
    }
  };

  const handleSelectPrompt = async (index: number) => {
    const prompt = prompts[index];
    const selected = !prompt.selected;

    let newPrompts = [...prompts];

    if (user) {
      const { displayName, email, uid, photoURL } = user;

      if (selected) {
        const docRef = await addDoc(collection(db, "drafts"), {
          approved: false,
          createdAt: serverTimestamp(),
          creator: { displayName, email, uid, photoURL },
          text: prompt.text,
        });
        console.log("Prompt written with ID: ", docRef.id);

        newPrompts[index] = {
          ...newPrompts[index],
          selected,
          docId: docRef.id,
        };
      } else if (prompt.docId) {
        await deleteDoc(doc(db, "drafts", prompt.docId));
        console.log("Deleted", prompt.docId);
        newPrompts[index] = {
          ...newPrompts[index],
          selected,
        };
      }

      setPrompts(newPrompts);
    } else {
      console.error("User must be signed in");
    }

    // TODO: add prompt to drafts firestore
  };

  if (loading)
    return (
      <div className="flex flex-1 items-center justify-center flex-col h-full">
        <h1 className="flex-1 text-white text-xl font-black text-center items-center flex justify-center">
          Loading...
        </h1>
      </div>
    );

  if (screen === "bankrupt")
    return (
      <div className="flex flex-1 items-center justify-center flex-col h-full">
        <div className={"flex flex-2 items-center justify-center flex-col"}>
          <h1 className="text-red text-xl font-black text-center items-center flex justify-center">
            Bankrupt!
          </h1>

          <h1 className="text-white text-lg font-black text-center items-center flex justify-center opacity-50">
            Please contact Christian to get more coins.
          </h1>
        </div>
        <div style={{ flex: 3 }} />
      </div>
    );

  if (screen === "seeds")
    return (
      <div className="flex flex-1 items-center justify-center flex-col h-full">
        <div className="flex-4 flex">
          <Carousel>
            <CarouselItem>
              <Card
                text={seeds.a}
                onChange={(text: string) => handleChange("a", text)}
              />
            </CarouselItem>
            <CarouselItem>
              <Card
                text={seeds.b}
                onChange={(text: string) => handleChange("b", text)}
              />
            </CarouselItem>
          </Carousel>

          <div className="flex-1" />
        </div>

        <div className={"flex flex-1"}>
          <div
            className={"flex-1 flex justify-center"}
            onClick={handleGenerate}
          >
            <h1 className="flex-1 text-green text-xl font-black text-center items-center flex justify-center">
              Generate
            </h1>
          </div>
        </div>
      </div>
    );

  return (
    <div className="flex flex-1 flex-col h-full">
      <div
        className="mx-8 overflow-y-auto h-0"
        style={{ flexShrink: 1, flexGrow: 3 }}
      >
        {prompts.map((prompt, index) => (
          <div
            key={index}
            className={`flex bg-white px-6 py-8 w-full mt-4 ${
              prompt.selected ? "bg-green" : ""
            }`}
            style={{ borderRadius: 30 }}
            onClick={() => handleSelectPrompt(index)}
          >
            <p
              className={`flex-1 font-black text-base text-left select-none ${
                prompt.selected ? "text-white" : "text-black"
              }`}
            >
              {prompt.text}
            </p>
          </div>
        ))}
      </div>

      <div className={"flex-1"} />

      <div
        className={"absolute z-1 bottom-0 bg-black w-full"}
        style={{ height: "10vh" }}
      >
        <div className={"flex flex-1"}>
          <div
            className={"flex-1 flex justify-center"}
            onClick={() => setScreen("seeds")}
          >
            <h1 className="flex-1 text-white opacity-50 text-xl font-black text-center items-center flex justify-center">
              Back
            </h1>
          </div>
          <div
            className={"flex-1 flex justify-center"}
            onClick={handleGenerate}
          >
            <h1 className="flex-1 text-green text-xl font-black text-center items-center flex justify-center">
              More
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptsScreen;

const Card = ({ onChange, text }: { onChange: any; text: string }) => {
  return (
    <div className="flex-1 flex items-center justify-center mt-8">
      <div
        className="flex bg-white px-6 py-8"
        style={{ width: "30vh", height: "40vh", borderRadius: 30 }}
      >
        <textarea
          className="flex-1 text-black font-black text-base text-left resize-none"
          value={text}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};
